import React from 'react'
import Navbar from '../../../NewNavBar/Navbar'
import {
  Container,
  Box,
  Text,
  Image,
  SimpleGrid,
  Input,
  Textarea,
  Button,
} from '@chakra-ui/react'
import styled from 'styled-components'
// import { Row, Col } from 'react-flexbox-grid'
// import CommonButton from '../../components/NewButton/index'

const PropertiesHeaderWrapper = styled.section`
  display: inline-block;
  width: 100%;
  padding: 43px 0px 38px;
  background: #131210;
`

const PropertiesBannerWrapper = styled.section`
  background-image: url(${({ image }) => image && image});
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 1302px;
  height: auto;
  width: 100%;
  overflow: hidden;
  position: relative;

  @media (max-width: 991px) {
    min-height: 1080px;
  }

  @media (max-width: 767px) {
    min-height: 750px;
  }
`

export const BannerBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 11;
`

const PropertiesBanner = ({ title, subtitle, bgImage, images }) => {
  const [bannerImg1, bannerImg2] = images

  return (
    <>
      <PropertiesHeaderWrapper>
        <Container
          maxW={[
            'container.sm',
            'container.md',
            'container.lg',
            'container.xl',
          ]}
        >
          <Navbar />
        </Container>
      </PropertiesHeaderWrapper>
      <PropertiesBannerWrapper image={`${bgImage?.file?.url}?w=640`}>
        <BannerBg>
          <Container
            maxW={[
              'container.sm',
              'container.md',
              'container.lg',
              'container.xl',
            ]}
          >
            <SimpleGrid
              columns={[1, 1, 1, 2]}
              spacing={[30, , , 0]}
              paddingTop={['70px', '', '', '100px']}
            >
              <Box display="flex" flexFlow="column">
                <Text
                  as={'h1'}
                  fontSize={['42px', '60px', '66px', '80px']}
                  lineHeight={['42px', '60px', '66px', '80px']}
                  color="#1E3240"
                  fontWeight="bold"
                  letterSpacing="-2.01026px"
                  marginBottom={['17px', '', '', '27px']}
                >
                  {title}
                </Text>
                <Text
                  fontSize={['16px', '', '18px', '36px']}
                  color="#000000"
                  opacity="0.6"
                  marginBottom={['70px', '', '', '100px']}
                >
                  {subtitle}
                </Text>
                <Box
                  display="flex"
                  position="relative"
                  padding="0px 40px 0px 40px"
                  _before={{
                    content: '""',
                    position: 'absolute',
                    top: '-37px',
                    left: '0px',
                    width: '134px',
                    height: '122px',
                    background: '#83B53C',
                  }}
                  _after={{
                    content: '""',
                    position: 'absolute',
                    bottom: '-44px',
                    right: '0px',
                    width: '134px',
                    height: '122px',
                    background: 'transperant',
                    border: '4px solid #83B53C',
                  }}
                >
                  <Image
                    src={`${bannerImg1.file.url}?w=640`}
                    alt="banner_img1"
                    width="100%"
                    objectFit="cover"
                    height={['300px', '300px', '643px', '643px']}
                    boxShadow="0px 4px 4px 5px rgba(0, 0, 0, 0.25)"
                    zIndex="1"
                  />
                </Box>
              </Box>
              <Box display={['none', 'none', 'none', 'flex']} flexFlow="column">
                <Box
                  display="flex"
                  position="relative"
                  padding="0px 40px 0px 40px"
                  _before={{
                    content: '""',
                    position: 'absolute',
                    top: '-37px',
                    right: '0px',
                    width: '134px',
                    height: '122px',
                    background: '#83B53C',
                  }}
                  _after={{
                    content: '""',
                    position: 'absolute',
                    bottom: '-44px',
                    left: '0px',
                    width: '134px',
                    height: '122px',
                    background: 'transperant',
                    border: '4px solid #83B53C',
                    zIndex: '-1',
                  }}
                >
                  <Image
                    src={`${bannerImg2.file.url}?w=640`}
                    alt="banner_img1"
                    width="100%"
                    objectFit="cover"
                    height={['300px', '300px', '643px', '643px']}
                    boxShadow="0px 4px 4px 5px rgba(0, 0, 0, 0.25)"
                  />
                </Box>
              </Box>
            </SimpleGrid>
          </Container>
        </BannerBg>
      </PropertiesBannerWrapper>
    </>
  )
}

export default PropertiesBanner
