import React, { useState, useRef } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import { HiOutlineChevronRight, HiOutlineChevronLeft } from 'react-icons/hi'
import {
  Container,
  Box,
  Text,
  Grid,
  GridItem,
  Avatar,
  Button,
  SimpleGrid,
  Image,
  Progress,
  ChakraProvider,
  extendTheme,
  ScaleFade,
} from '@chakra-ui/react'
import { Link } from '@chakra-ui/react'
const SliderAllWrapper = styled.div`
  display: flex;
  .slick-slide {
    padding: 0 10px;
  }
`

const SliderWrapper = styled(Slider)`
  display: inline-block;
  width: 100%;

  .slick-prev {
    display: none !important;
  }

  .slick-next {
    display: none !important;
  }
`

const SliderButton = styled(Button)`
  svg {
    font-size: 22px;
  }

  .chakra-button__icon {
    margin-inline-start: 0px;
  }
`

const ProgressBar = styled(Progress)``

const DiscoverSlider = ({ cards = [] }) => {
  const [state, setState] = useState({
    slideIndex: 0,
    updateCount: 0,
  })

  const slider = useRef()

  function next() {
    slider.current.slickNext()
  }
  function previous() {
    slider.current.slickPrev()
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    afterChange: () => {},
    // setState((state) => ({ updateCount: state.updateCount + 1 })),
    beforeChange: (current, next) => setState({ slideIndex: next }),
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <>
      <Grid
        templateColumns={[
          'repeat(7, 1fr)',
          'repeat(12, 1fr)',
          'repeat(6, 1fr)',
          'repeat(12, 1fr)',
        ]}
        alignItems="center"
        marginBottom="32px"
      >
        <GridItem colSpan={[5, 9, 5, 10]}>
          <ProgressBar
            value={state.slideIndex}
            min={0}
            max={3}
            size="xs"
            colorScheme="green"
          />
        </GridItem>
        <GridItem
          colStart={[6, 11, 6, 11]}
          colEnd={[8, 13, 7, 13]}
          display="flex"
          justifyContent="flex-end"
        >
          <SliderButton
            onClick={previous}
            rightIcon={<HiOutlineChevronLeft />}
            color="white"
            background="#83B53C"
            variant="solid"
            borderRadius="50%"
            height={['32px', '42px', , '64px']}
            width={['32px', '42px', , '64px']}
            minWidth={['32px', '42px', , '64px']}
            display="flex"
            justifyContent="center"
            alignItems="center"
            _focus={{ boxShadow: 'none', background: '#83B53C' }}
          />
          <SliderButton
            onClick={next}
            rightIcon={<HiOutlineChevronRight />}
            color="white"
            background="#83B53C"
            variant="solid"
            borderRadius="50%"
            height={['32px', '42px', , '64px']}
            width={['32px', '42px', , '64px']}
            minWidth={['32px', '42px', , '64px']}
            marginLeft={['10px', '12px', , '24px']}
            display="flex"
            justifyContent="center"
            alignItems="center"
            _focus={{ boxShadow: 'none', background: '#83B53C' }}
          />
        </GridItem>
      </Grid>
      <SliderAllWrapper>
        <SliderWrapper ref={slider} {...settings}>
          {(cards || []).map((item) => (
            <Box
              display="flex"
              flexFlow="column"
              cursor="pointer"
              border="1px solid #6a65657a"
              borderRadius="4px"
              boxShadow="0px 4px 10px #6a65652b"
            >
              <Image
                src={`${item.file.file.url}?w=220`}
                width="100%"
                height="249px"
                objectFit="cover"
              />
              <Box display="flex" flexFlow="column" padding="26px 20px">
                <Text
                  as={'h3'}
                  fontSize={[, , , '24px']}
                  fontWeight="bold"
                  lineHeight={[, , , '29px']}
                  marginBottom={[, , , '10px']}
                  color="#6A6565"
                >
                  {item.title}
                </Text>
                <Text
                  as={'p'}
                  fontSize={[, , , '17px']}
                  fontWeight="100"
                  lineHeight={[, , , '1']}
                  color="#6A6565"
                  marginBottom="5px"
                >
                  {item.subtitle}
                </Text>
                <Text fontSize={[, , , '17px']} color="#141B2D">
                  <Link color="#1C3988" href="/">
                    {item.description.description}
                  </Link>
                </Text>
              </Box>
            </Box>
          ))}
        </SliderWrapper>
      </SliderAllWrapper>
      {/* </ChakraProvider> */}
    </>
  )
}

export default DiscoverSlider
