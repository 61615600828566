import React from 'react'
import {
  Container,
  Box,
  Text,
  Image,
  Grid,
  Avatar,
  Button,
  SimpleGrid,
} from '@chakra-ui/react'
import styled from 'styled-components'
//import HouseImage from '../../../../assets/pngs/house.png'
//import HouseTreeImage from '../../../../assets/pngs/houseTree.png'

const AboutInvestmentWrapper = styled.section`
  display: inline-block;
  width: 100%;
  padding: 128px 0px 36px;

  @media (max-width: 767px) {
    padding: 50px 0px 36px;
  }
`
const ImageWrapper = styled(Box)`
  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: -12px;
    width: 7px;
    height: 303px;
  }

  @media (max-width: 991px) {
    ::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: -12px;
      width: 7px;
      height: 303px;
      background: #83b53c;
      display: none;
    }
  }
`

const TextWrapper = styled(Box)`
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: -18px;
    width: 7px;
    height: 303px;
    background: #83b53c;
  }

  @media (max-width: 991px) {
    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: -18px;
      width: 7px;
      height: 303px;
      background: #83b53c;
      display: none;
    }
  }
`

const AboutDescription = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 13;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 463px;
  width: 100%;

  span {
    color: #83b53c;
  }

  @media (max-width: 991px) {
    height: auto;
  }
`

const AboutInvestment = ({
  description,
  file,
  title,
  actions = [],
  isBackgroundGreen,
  isAfterCircle,
  isImageLeft,
  subtitle,
}) => {
  return (
    <AboutInvestmentWrapper>
      <Container
        maxW={['container.sm', 'container.md', 'container.lg', 'container.xl']}
      >
        <SimpleGrid columns={[1, 1, 2, 2]} spacing={[30, , 20, 100]}>
          {isImageLeft ? (
            <>
              <ImageWrapper display="flex" position="relative">
                <Image
                  src={`${file?.file?.url}?w=360`}
                  alt="about_img"
                  width="100%"
                  objectFit="cover"
                  height={['300px', '300px', '100%', '100%']}
                  paddingLeft={['0px', '', '', '39px']}
                />
              </ImageWrapper>
              <Box>
                <TextWrapper
                  display="flex"
                  flexFlow="column"
                  position="relative"
                >
                  <Text
                    as={'h3'}
                    fontWeight="bold"
                    fontSize={['20px', '', '', '50px']}
                    lineHeight={['36px', '', '', '58px']}
                    color={isBackgroundGreen ? '#FFFFFF' : '#1E3240'}
                    marginBottom="11px"
                  >
                    {title}
                  </Text>
                  <Text
                    as={AboutDescription}
                    fontSize={['16px', '', '18px', '18px']}
                    color={isBackgroundGreen ? '#FFFFFF' : '#000000'}
                    mixBlendMode="normal"
                    opacity={isBackgroundGreen ? '1' : '0.6'}
                    marginBottom="11px"
                    dangerouslySetInnerHTML={{
                      __html: description.description || '',
                    }}
                  />
                </TextWrapper>
              </Box>
            </>
          ) : (
            <>
              <Box display="flex" flexFlow="column">
                <Text
                  as={'h3'}
                  fontWeight="bold"
                  fontSize={['20px', '', '', '50px']}
                  lineHeight={['36px', '', '', '58px']}
                  color={isBackgroundGreen ? '#FFFFFF' : '#1E3240'}
                  marginBottom="14px"
                >
                  {title}
                </Text>
                <Text
                  as={'h4'}
                  fontWeight="bold"
                  fontSize={['28px', '', '', '36px']}
                  lineHeight={['36px', '', '', '44px']}
                  color={isBackgroundGreen ? '#FFFFFF' : '#979797'}
                  marginBottom="13px"
                >
                  {subtitle}
                </Text>
                <Text
                  as={AboutDescription}
                  fontSize={['16px', '', '18px', '18px']}
                  color={isBackgroundGreen ? '#FFFFFF' : '#000000'}
                  mixBlendMode="normal"
                  opacity={isBackgroundGreen ? '1' : '0.6'}
                  marginBottom="11px"
                  dangerouslySetInnerHTML={{
                    __html: description.description || '',
                  }}
                />
              </Box>
              <Box display="flex" marginBottom="96px" width="100%">
                {isAfterCircle ? (
                  <>
                    <Box display="flex" position="relative" width="100%">
                      <Image
                        src={`${file?.file?.url}?w=360`}
                        alt="about_img"
                        width="100%"
                        objectFit="cover"
                        height={['300px', '300px', '100%', '100%']}
                        zIndex="1"
                        paddingRight={['0px', '', '', '59px']}
                      />
                      <Box
                        display={['none', '', '', 'block']}
                        width="152px"
                        height="152px"
                        background="#83B53C"
                        borderRadius="50%"
                        boxShadow="0px 0px 0px 8px rgba(131, 181, 60, 40%)"
                        position="absolute"
                        left="-59px"
                        top="-58px"
                      />
                      <Box
                        display={['none', '', '', 'block']}
                        width="152px"
                        height="152px"
                        background="#83B53C"
                        borderRadius="50%"
                        boxShadow="0px 0px 0px 8px rgba(131, 181, 60, 40%)"
                        position="absolute"
                        right="0px"
                        top="-58px"
                      />
                      <Box
                        display={['none', '', '', 'block']}
                        width="152px"
                        height="152px"
                        background="#83B53C"
                        borderRadius="50%"
                        boxShadow="0px 0px 0px 8px rgba(131, 181, 60, 40%)"
                        position="absolute"
                        left="-59px"
                        bottom="-58px"
                      />
                      <Box
                        display={['none', '', '', 'block']}
                        width="152px"
                        height="152px"
                        background="#83B53C"
                        borderRadius="50%"
                        boxShadow="0px 0px 0px 8px rgba(131, 181, 60, 40%)"
                        position="absolute"
                        right="0px"
                        bottom="-58px"
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box display="flex" position="relative" width="100%">
                      <Image
                        src={`${file?.file?.url}?w=360`}
                        alt="about_img"
                        width="100%"
                        objectFit="cover"
                        height={['300px', '300px', '100%', '100%']}
                        zIndex="1"
                        paddingRight={['0px', '', '', '62px']}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </>
          )}
        </SimpleGrid>
      </Container>
    </AboutInvestmentWrapper>
  )
}

export default AboutInvestment
