import React from 'react'
import {
  Container,
  Box,
  Text,
  Image,
  SimpleGrid,
  Flex,
  Grid,
  Link,
} from '@chakra-ui/react'
import styled from 'styled-components'
import CommonButton from '../../../NewButton/index'

const BookContactVWrapper = styled.section`
  display: inline-block;
  width: 100%;
  padding: 45px 0px;
  background: #fafff4;
`

const BookContact = ({ title, description, actions }) => {
  return (
    <BookContactVWrapper>
      <Container
        maxW={['container.sm', 'container.md', 'container.lg', 'container.xl']}
      >
        <SimpleGrid
          display="flex"
          alignItems="center"
          flexWrap={['wrap', , 'nowrap', 'nowrap']}
          columns={[2, 2, 2, 2]}
          spacing={[30, , , 24, 78]}
        >
          <Box display="flex" flexFlow="column">
            <Text
              as={'h3'}
              fontSize={['28px', '', '', '32px']}
              lineHeight={['36px', '', '', '44px']}
              fontWeight="bold"
              color="#6A6565"
              marginBottom="8px"
            >
              {title}
            </Text>
            <Text fontSize={['16px', '', '18px', '20px']} color="#979797">
              {description.description}
            </Text>
          </Box>
          {(actions || []).map((action) => (
            <Link href="#contact-us">
              <Box display="flex">
                <CommonButton color="#FFFFFF" iconColor="#FFFFFF">
                  {action.text}
                </CommonButton>
              </Box>
            </Link>
          ))}
        </SimpleGrid>
      </Container>
    </BookContactVWrapper>
  )
}

export default BookContact
