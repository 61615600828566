import React from 'react'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { P, H2 } from '../Globals/index.style'
import { StyledSlider, Logo, LogoContainer, TextAlign } from './style'
import PropTypes from 'prop-types'

const LogoCarousel = ({ images, subtitle, title }) => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 'width: 1170px',
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 'width: 960px',
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 'width: 760px',
        centerMode: true,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  return (
    <>
      <TextAlign>
        <H2>{title}</H2>
        <P>{subtitle}</P>
      </TextAlign>
      <StyledSlider {...settings}>
        {(images || []).map((image) => (
          <LogoContainer key={image.src}>
            <Logo src={image.src} alt={image.alt} />
          </LogoContainer>
        ))}
      </StyledSlider>
    </>
  )
}

LogoCarousel.defaultProps = {
  images: [],
}

LogoCarousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
    })
  ),
}

export default LogoCarousel
