import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Row, Col } from 'react-flexbox-grid'
import {
  Wrapper,
  IconGrid,
  Icon,
  Title,
  SubText,
  TextAlign,
  GridItems,
  TitleWrapper,
} from './style'

import { H2, P } from '../Globals/index.style'

const GridLayout = ({ gridItems, subtitle, title, enable }) => {
  const GridItem = ({ item, index }) => {
    return (
      <IconGrid>
        <Icon src={item.file.url} alt={index} />
        {item.title && <Title>{item.title}</Title>}
        {item.description && <SubText>{item.description}</SubText>}
      </IconGrid>
    )
  }

  return (
    <>
      <Wrapper enable={enable}>
        <TextAlign>
          <Grid>
            {(title || subtitle) && (
              <Row center="lg">
                <Col xs={12} lg={9}>
                  <TitleWrapper>
                    {title && <H2>{title}</H2>}
                    {subtitle && <P>{subtitle}</P>}
                  </TitleWrapper>
                </Col>
              </Row>
            )}
            <div>
              <Row>
                {(gridItems || []).map((item, index) => (
                  <GridItems>
                    <GridItem item={item} index={index} />
                  </GridItems>
                ))}
              </Row>
            </div>
          </Grid>
        </TextAlign>
      </Wrapper>
    </>
  )
}

GridLayout.defaultProps = {
  gridItems: [],
  enable: true,
}

GridLayout.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  enable: PropTypes.bool,
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
    })
  ),
}

export default GridLayout
