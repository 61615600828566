import React, { useMemo, useRef } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import PropertiesBanner from '../components/PageComponent/PropertiesPageComponent/Banner/index'
import PageBanner from '../components/PageBanner/index'
import About from '../components/PageComponent/HomePageComponent/About/index'
import VideoInfo from '../components/PageComponent/HomePageComponent/VideoInfo/index'
import BookContact from '../components/PageComponent/HomePageComponent/BookContact/index'
import Partner from '../components/PageComponent/HomePageComponent/Partner/index'
import MyBlockValues from '../components/PageComponent/HomePageComponent/MyBlockValues/index'
import DiscoverProperties from '../components/PageComponent/HomePageComponent/DiscoverProperties/index'
import ContactUs from '../components/PageComponent/HomePageComponent/ContactUs/index'
import SubBanner from '../components/PageComponent/VisionPageComponent/SubBanner/index'
import DiscoverPropertiesDetails from '../components/PageComponent/PropertiesPageComponent/DiscoverPropertiesDetails/index'
import AboutInvestment from '../components/PageComponent/InvestmentComponent/AboutInvestment/index'
import { Section, Grid, Spacer } from '../components/Globals/index.style'
import LogoCarousel from '../components/LogoCarousel/index'
import Form from '../components/Form/index'
import BlockFeatureCard from '../components/BlockFeatureCard/index'
import GridLayout from '../components/GridIconCom/index'
import CallToAction from '../components/CallToAction/index'
import BlockCards from '../components/BlockCards/index'
import NewFooter from '../components/NewFooter'
import _ from 'lodash'
import { useInterval, useInViewport } from 'react-in-viewport'

const fetchSection = (name, props = {}) => {
  switch (name) {
    case 'about-section':
      return <About {...props} />

    case 'book-section':
      return <BookContact {...props} />

    case 'myBlock-section':
      return <MyBlockValues {...props} />

    case 'discoverProperties-section':
      return <DiscoverProperties {...props} />

    case 'partner-section':
      return <Partner {...props} />

    case 'subBanner-Section':
      return <SubBanner {...props} />

    case 'discoverProperties-section-details':
      return <DiscoverPropertiesDetails {...props} />

    case 'investment-about-section':
      return <AboutInvestment {...props} />

    case 'lifestyle-about-section':
      return <AboutInvestment {...props} />

    case 'residences-about-section':
      return <AboutInvestment {...props} />

    default:
      return null
  }
}

const PageTemplate = ({ data }) => {
  const { sections, contactUs, footer, banner, slug } = data.contentfulPageOp

  const { footerCards, subFooterTitle } = data.contentfulPageOp.footer
  const { title, bgImage, withNavbar, subtitle, images, isCenter, actions } =
    data.contentfulPageOp.banner
  const [footerPart1, footerPart2, footerPart3, footerPart4] = footerCards

  return (
    <>
      <Helmet>{/* <title>{seo}</title> */}</Helmet>

      {banner && (
        <>
          {banner && withNavbar ? (
            <PageBanner
              title={title}
              bgImage={bgImage}
              actions={actions}
              subtitle={subtitle}
              isCenter={isCenter}
            />
          ) : (
            <PropertiesBanner
              title={title}
              subtitle={subtitle}
              bgImage={bgImage}
              images={images}
            />
          )}
        </>
      )}

      {sections && sections.length
        ? (sections || []).map(({ name, section, ...props }) =>
            fetchSection(name, { ...props, ...section })
          )
        : null}

      {contactUs && (
        <ContactUs
          title={contactUs.title}
          subtitle={contactUs.subtitle}
          description={contactUs.description.description}
          actions={contactUs.actions}
          file={contactUs.file.file.url}
        />
      )}

      {footer && (
        <NewFooter
          footerPart1={footerPart1}
          footerPart2={footerPart2}
          footerPart3={footerPart3}
          footerPart4={footerPart4}
          subFooterTitle={subFooterTitle}
        />
      )}

      {/* <About />
      <VideoInfo />
      <BookContact />
      <Partner logos={LogosData} />
      <MyBlockValues />
      <DiscoverProperties />
      <ContactUs />
      <NewFooter /> */}
    </>
  )
}

export const query = graphql`
  query pageQuery($slug: String!) {
    contentfulPageOp(slug: { eq: $slug }) {
      name
      slug
      sections {
        id
        name
        parentsSlug
        title
        sectionTitle
        section {
          ... on ContentfulSectionInfoOp {
            id
            title
            videoLink
            subtitle
            description {
              description
            }
            file {
              file {
                url
              }
            }
            isImageLeft
            isAfterCircle
            isBackgroundGreen
            slug
            actions {
              text
              to
            }
            cards {
              ... on ContentfulSingleCardInfoOp {
                id
                title
                subtitle
                description {
                  description
                }
                file {
                  file {
                    url
                  }
                }
                actions {
                  to
                  text
                  shower
                  bed
                  artBoard
                }
                slug
              }
              ... on ContentfulMultiSectionsWrapper {
                id
                slug
                multiSections {
                  title
                  slug
                  isGreen
                  actions {
                    to
                    text
                  }
                  subSections {
                    title
                    subtitle
                    description {
                      description
                    }
                    file {
                      file {
                        url
                      }
                    }
                    actions {
                      to
                      text
                      shower
                      bed
                      artBoard
                    }
                    slug
                  }
                }
              }
            }
          }
        }
        partnersLogo {
          title
          image {
            file {
              url
            }
          }
        }
      }
      banner {
        actions {
          text
          to
        }
        bgImage {
          file {
            url
          }
        }
        title
        withNavbar
        subtitle
        slug
        images {
          file {
            url
          }
        }
        isCenter
      }
      contactUs {
        id
        actions {
          text
          to
        }
        slug
        subtitle
        title
        description {
          description
        }
        file {
          file {
            url
          }
        }
      }
      footer {
        subFooterTitle
        slug
        footerCards {
          title
          subtitle
          slug
          actions {
            to
            text
          }
          file {
            file {
              url
            }
          }
          pages {
            slug
            name
            title
          }
        }
      }
      title
    }
  }
`
export default PageTemplate
