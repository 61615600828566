import React, { useRef } from 'react'
import { Container, Box, Text, Image, SimpleGrid, Flex } from '@chakra-ui/react'
import CommonButton from '../../../NewButton/index'
import styled from 'styled-components'

import {
  Heading,
  HStack,
  Icon,
  Link,
  Skeleton,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa'
import { useInViewport } from 'react-in-viewport'

const AboutWrapper = styled.section`
  display: inline-block;
  width: 100%;
  padding: 12vh 0px;
  background: ${({ isBackgroundGreen }) =>
    isBackgroundGreen ? '#83b53c' : '#FFFFFF'};
`

const VideoWrapper = styled.div`
  iframe {
    border: 4px solid #ffffff;
    border-radius: 50px 50px 0px 0px;
    height: 522px;
    /* height: 674px; */
    width: 100%;
  }

  @media (max-width: 991px) {
    iframe {
      height: 100%;
    }
  }

  @media (max-width: 767px) {
    iframe {
      height: 300px;
    }
  }
`

const AboutDescription = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 13;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 463px;
  width: 100%;

  @media (max-width: 991px) {
    height: auto;
  }
`

const About = ({
  file,
  title,
  description,
  videoLink,
  actions = [],
  isBackgroundGreen,
}) => {
  const ref = useRef(null)
  const { enterCount } = useInViewport(
    ref,
    { rootMargin: '-100px' },
    { disconnectOnLeave: false },
    {}
  )
  return (
    <AboutWrapper isBackgroundGreen={isBackgroundGreen}>
      <Container
        maxW={['container.sm', 'container.md', 'container.lg', 'container.xl']}
      >
        <SimpleGrid columns={[1, 1, 2, 2]} spacing={[30, , , 78]}>
          {file !== null && videoLink !== 'null' ? (
            <>
              <Box as={VideoWrapper} display="flex" ref={ref}>
                <iframe
                  src={videoLink}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </Box>
            </>
          ) : (
            <>
              {file && (
                <Box ref={ref}>
                  <Image
                    src={`${file?.file?.url}?w=360`}
                    alt="about_img"
                    width="100%"
                    objectFit="cover"
                    height={['300px', '300px', '100%', '522px']}
                    borderRadius="0px 57px 0px 0px"
                  />
                </Box>
              )}
              {videoLink !== 'null' && (
                <Box as={VideoWrapper} display="flex">
                  <iframe
                    src={videoLink}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </Box>
              )}
            </>
          )}

          {file === null && videoLink === 'null' && <Box display="flex"></Box>}

          <Box>
            <Box
              display="flex"
              flexFlow="column"
              position="relative"
              maxHeight="520px"
              _before={{
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '169px',
                height: '4px',
                background: isBackgroundGreen
                  ? '#FFFFFF'
                  : 'linear-gradient(90deg, #83B53C 0%, #000000 94.96%)',
                borderRadius: '2px',
              }}
            >
              <Text
                as={'h3'}
                fontWeight="bold"
                fontSize={['20px', '', '', '36px']}
                lineHeight={['36px', '', '', '58px']}
                color={isBackgroundGreen ? '#FFFFFF' : '#6A6565'}
                marginBottom="11px"
                marginTop="19px"
              >
                {title}
              </Text>
              <Text
                as={AboutDescription}
                fontSize={['16px', '', '18px', '20px']}
                color={isBackgroundGreen ? '#FFFFFF' : '#6A6565'}
                mixBlendMode="normal"
                opacity={isBackgroundGreen ? '1' : '0.6'}
                marginBottom="11px"
                maxHeight="376px"
                dangerouslySetInnerHTML={{
                  __html: description.description || '',
                }}
              />
              {actions.length !== 0 && (
                <>
                  {(actions || []).map((action) => (
                    <Flex>
                      <CommonButton
                        // color="#FFFFFF"
                        iconColor={
                          isBackgroundGreen
                            ? 'rgba(0, 0, 0, 0.837986)'
                            : '#FFFFFF'
                        }
                        // iconColor="#FFFFFF"
                        background={isBackgroundGreen ? '#FFFFFF' : '#83B53C'}
                        color={isBackgroundGreen ? '#83B53C' : '#FFFFFF'}
                      >
                        {action.text}
                      </CommonButton>
                    </Flex>
                  ))}
                </>
              )}
            </Box>
          </Box>
        </SimpleGrid>
      </Container>
    </AboutWrapper>
  )
}

export default About
