import React from 'react'
import {
  Container,
  Box,
  Text,
  Image,
  SimpleGrid,
  Input,
  Textarea,
  Button,
} from '@chakra-ui/react'
import styled from 'styled-components'
import OurMansions from './OurMansions/index'

const DiscoverPropertiesWrapper = styled.section`
  display: inline-block;
  width: 100%;
  padding: 45px 0px;
  background: #fafff4;
`

const DiscoverPropertiesDetails = ({ sectionTitle, cards }) => {
  return (
    <DiscoverPropertiesWrapper>
      <Container
        maxW={['container.sm', 'container.md', 'container.lg', 'container.xl']}
      >
        <Text
          fontSize={['42px', '60px', '66px', '80px']}
          lineHeight={['42px', '60px', '66px', '80px']}
          color="#1E3240"
          fontWeight="bold"
          letterSpacing="-2.01026px"
          textAlign="center"
          marginBottom="52px"
        >
          {sectionTitle}
        </Text>
        <OurMansions cards={cards} />
      </Container>
    </DiscoverPropertiesWrapper>
  )
}

export default DiscoverPropertiesDetails
