import styled from 'styled-components'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const StyledSlider = styled(Slider)`
  .slick-dots {
    button::before {
      color: red;
      font-size: 8px;
    }
  }

  .slick-dots {
    li.slick-active {
      button::before {
        color: red;
        opacity: 1;
      }
    }
  }

  .slick-track {
    display: flex;
    align-items: center;
  }

  &.slick-slider {
    width: 968px !important;
    margin: 0 auto;
  }
`

export const LogoContainer = styled.div`
  outline: none;
  display: inline-flex !important;
  justify-content: center;
`

export const Logo = styled.img`
  max-width: 160px;
  object-fit: contain;
  padding: 0;
  max-height: 80px;
`

export const SubText = styled.p`
  margin: 0 auto;
  margin-bottom: 5px;
  max-width: 760px;
  @media (min-width: 760px) {
    margin-bottom: 48px;
  }
`
export const TextAlign = styled.div`
  display: flex;
  margin-bottom: 4em;
  flex-direction: column;
  p {
    padding: 10px;
    text-align: center;
  }
`
