import React, { useRef } from 'react'
import {
  Container,
  Box,
  Text,
  Grid,
  Avatar,
  Button,
  SimpleGrid,
  ScaleFade,
} from '@chakra-ui/react'
import styled from 'styled-components'
import DiscoverSlider from './DiscoverSlider'

const DiscoverWrapper = styled.section`
  display: inline-block;
  width: 100%;
  padding: 77px 0px 120px;
  background: #fdfefb;
`

const DiscoverProperties = ({
  title,
  description,
  actions = [],
  cards = [],
}) => {
  return (
    <DiscoverWrapper>
      <Container
        maxW={['container.sm', 'container.md', 'container.lg', 'container.xl']}
      >
        <SimpleGrid
          display="flex"
          alignItems="flex-end"
          flexWrap={['wrap', , 'nowrap', 'nowrap']}
          columns={[2, 2, 2, 2]}
          spacing={[30, , , 24, 180]}
          marginBottom="100px"
        >
          <Box display="flex" flexFlow="column">
            <Text
              as={'h3'}
              fontSize={['28px', , , '36px']}
              fontWeight="bold"
              lineHeight={['36px', , , '58px']}
              color="#6A6565"
            >
              {title}
            </Text>
            <Text fontSize={['16px', '', '18px', '20px']} color="#979797">
              {description.description}
            </Text>
          </Box>
          {actions.map((item) => (
            <Box>
              <Button
                variant="outline"
                fontSize="16px"
                fontWeight="bold"
                color="#979797"
                background="#ffffff"
                outline="none !important"
                boxShadow="none"
                borderRadius="4px"
                height="48px"
                border="2px solid"
                borderColor="#83B53C"
                _hover={{ background: '#ffffff' }}
                _focus={{ boxShadow: 'none' }}
              >
                {item.text}
              </Button>
            </Box>
          ))}
        </SimpleGrid>
        <DiscoverSlider cards={cards} />
      </Container>
    </DiscoverWrapper>
  )
}

export default DiscoverProperties
