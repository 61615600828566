import React from 'react'
import { Container, Box, Text, Image, SimpleGrid, Flex } from '@chakra-ui/react'
import styled from 'styled-components'

const VideoInfoWrapper = styled.section`
  display: inline-block;
  width: 100%;
  padding: 24px 0px 17px;
  background: #83b53c;
  border-radius: 50px 50px 0px 0px;
`

const VideoWrapper = styled.div`
  iframe {
    border: 4px solid #ffffff;
    border-radius: 50px 50px 0px 0px;
    height: 674px;
    width: 100%;
  }

  @media (max-width: 767px) {
    iframe {
      height: 400px;
    }
  }

  @media (max-width: 575px) {
    iframe {
      height: 300px;
    }
  }
`

const VideoDescription = styled.p`
  /* display: -webkit-box;
  -webkit-line-clamp: 13;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 463px;
  width: 100%; */
`

const VideoInfo = ({ title, description, file }) => {
  return (
    <VideoInfoWrapper>
      <Container
        maxW={['container.sm', 'container.md', 'container.lg', 'container.xl']}
      >
        <SimpleGrid columns={[1, 1, 2, 2]} spacing={[30, , , 78]}>
          <Box
            as={VideoWrapper}
            display="flex"
            paddingLeft={['0px', , , '17px']}
          >
            <iframe
              src="https://www.youtube.com/embed/_zokDL1U9NM"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </Box>
          <Box>
            {/* <Container maxW={['container.sm', 'container.md']}> */}
            <Box display="flex" flexFlow="column">
              <Text
                as={'h1'}
                fontWeight="bold"
                fontSize={['28px', '', '', '50px', '80px']}
                lineHeight={['36px', '', '', '58px', '88px']}
                color="#FFFFFF"
                marginBottom="20px"
              >
                {title}
              </Text>
              <Text
                as={VideoDescription}
                fontSize={['16px', '', '18px', '24px']}
                color="#FFFFFF"
              >
                {description.description}
              </Text>
            </Box>
            {/* </Container> */}
          </Box>
        </SimpleGrid>
      </Container>
    </VideoInfoWrapper>
  )
}

export default VideoInfo
