import React from 'react'
import { Container, Box, Text, Grid, Avatar, Button } from '@chakra-ui/react'
import styled from 'styled-components'
import {
  SearchHomeSvg,
  SellHomeSvg,
  RentHomeSvg,
} from '../../../../assets/svgs'

const fetchSection = (name) => {
  switch (name) {
    case 'Quality':
      return <SearchHomeSvg boxSize={9} />

    case 'Sell a home':
      return <SellHomeSvg />

    case 'Rent a home':
      return <RentHomeSvg />

    default:
      return null
  }
}

const MyBlockValuesWrapper = styled.section`
  display: inline-block;
  width: 100%;
  padding: 92px 0px 64px;
`

const CardDescription = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 144px;
  width: 100%;
  font-size: 20px;

  @media (max-width: 991px) {
    height: auto;
  }
`

const MyBlockValues = ({ title, description, cards = [] }) => {
  return (
    <MyBlockValuesWrapper>
      <Container
        maxW={['container.sm', 'container.md', 'container.lg', 'container.xl']}
      >
        <Box display="flex" flexFlow="column" justifyContent="center">
          <Text
            as={'h3'}
            fontSize={['28px', '', '', '36px']}
            lineHeight={['36px', '', '', '44px']}
            fontWeight="bold"
            color="#6A6565"
            textAlign="center"
            marginBottom="8px"
          >
            {title}
          </Text>
          <Text
            fontSize={['16px', '', '18px', '20px']}
            color="#979797"
            textAlign="center"
            padding={['0 6em']}
            marginBottom="131px"
          >
            {description.description}
          </Text>
        </Box>

        <Grid
          templateColumns={[
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
          ]}
          gap={['80px 10px']}
        >
          {(cards || []).map((card) => (
            <Box
              display="flex"
              flexFlow="column"
              transition="all .3s ease-out"
              width="100%"
              maxWidth="314"
              margin="0 auto"
              role="group"
              _hover={{
                transform: 'translate(0, -50px)',
              }}
            >
              <Box
                display="flex"
                flexFlow="column"
                justifyContent="center"
                alignItems="center"
                border="0.5px solid #83B53C"
                borderRadius="10px"
                padding="32px 16px"
                marginBottom="30px"
                position="relative"
                _after={{
                  content: `""`,
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  width: '100%',
                  height: '2px',
                  borderRadius: '0px 0px 4px 4px',
                  background: 'transparent',
                }}
                _groupHover={{
                  backgroundColor: 'rgba(131, 181, 60, 0.02)',
                  border: 'transparent',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  _after: {
                    background: '#83B53C',
                  },
                }}
              >
                <Avatar
                  bg="#83B53C"
                  height="80px"
                  width="80px"
                  boxShadow="-2px 4px 16px rgba(192, 223, 248, 0.8)"
                  icon={fetchSection(card.title)}
                  marginBottom="14px"
                />

                <Text
                  as={'h3'}
                  fontSize={['28px', '', '', '', '32px']}
                  lineHeight={['36px', '', '', '', '44px']}
                  fontWeight="bold"
                  color="#6A6565"
                  textAlign="center"
                  marginBottom="9px"
                  width="100%"
                  whiteSpace="pre-wrap"
                  _groupHover={{
                    color: '#83B53C',
                  }}
                >
                  {card.title}
                </Text>
                <Text
                  as={CardDescription}
                  fontSize={['16px', '', '18px', '20px']}
                  color="#626262"
                  lineHeight="36px"
                  textAlign="center"
                  _groupHover={{
                    color: '#83B53C',
                  }}
                >
                  {card.description.description}
                </Text>
              </Box>
              {/* {card.actions.map((item) => (
                <Button
                  color="#83B53C"
                  variant="link"
                  fontWeight="600"
                  fontSize="24px"
                  lineHeight="36px"
                  _hover={{
                    textDecoration: 'none',
                  }}
                >
                  {item.text}
                </Button>
              ))} */}
            </Box>
          ))}
        </Grid>
      </Container>
    </MyBlockValuesWrapper>
  )
}

export default MyBlockValues
