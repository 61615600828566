import React from 'react'
import {
  Container,
  Box,
  Text,
  Image,
  SimpleGrid,
  Flex,
  Grid,
} from '@chakra-ui/react'
import styled from 'styled-components'
import { RightArrowSvg } from '../../../../assets/svgs'
import Slider from 'react-slick'
import { Row, Col } from 'react-styled-flexboxgrid'

const PartnerWrapper = styled.section`
  display: inline-block;
  width: 100%;
  padding: 47px 0px;
  border: 1px solid #83b53c;
`

const SliderWrapper = styled(Slider)`
  display: inline-block;
  width: 100%;

  .slick-slide {
    display: flex !important;
    justify-content: center !important;
    padding: 0 4px;
  }

  .slick-prev {
    display: none !important;
  }

  .slick-next {
    display: none !important;
  }
`

const Partner = ({ cards = [], partnersLogo }) => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <PartnerWrapper>
      <Container
        maxW={['container.sm', 'container.md', 'container.lg', 'container.xl']}
      >
        <Row middle="xs">
          <Col xs={6} sm={4} md={3} lg={3}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Text
                as={'h3'}
                fontSize="20px"
                fontWeight="bold"
                lineHeight="28px"
                color="#6A6565"
                marginRight="32px"
              >
                Our Patners
              </Text>
              <RightArrowSvg
                boxSize={{ base: 6, lg: 8, xl: 10 }}
                color={'#83B53C'}
              />
            </Box>
          </Col>
          <Col xs={6} sm={8} md={9} lg={9}>
            <SliderWrapper {...settings}>
              {(partnersLogo?.image || []).map((logo, idx) => (
                <Box
                  display="flex"
                  justifyContent="center"
                  key={idx}
                  verticalAlign="middle"
                  _focusVisible={{
                    outline: 'none',
                    border: 'transpanrt',
                  }}
                >
                  <Image
                    src={logo?.file?.url}
                    alt={partnersLogo?.title}
                    objectFit="contain"
                    boxSize="120px"
                  />
                </Box>
              ))}
            </SliderWrapper>
          </Col>
        </Row>
      </Container>
    </PartnerWrapper>
  )
}

export default Partner
