import React from 'react'
import {
  Container,
  Box,
  Text,
  Grid,
  Image,
  SimpleGrid,
  Input,
  Textarea,
  Button,
} from '@chakra-ui/react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import CommonButton from '../../../../NewButton/index'
import ExtraInfoCard from '../Cards/extraInfoCard'
import { Row, Col } from 'react-styled-flexboxgrid'

const RowWrapper = styled(Row)`
  gap: 30px 0px;
`

const ColumnWrapper = styled(Col)`
  padding-right: 1.1rem;
  padding-left: 1.1rem;
`

const OurMansions = ({ cards }) => {
  const [sectionWrapper] = cards
  const multipleSections = sectionWrapper.multiSections
  // console.log(sectionWrapper)
  // console.log(multipleSections)

  return (
    <>
      {(multipleSections || []).map((section, index) => (
        <>
          <Box
            display="flex"
            flexFlow="column"
            padding={['28px 15px 59px', '', '28px 60px 59px', '28px 60px 59px']}
            backgroundColor={section.isGreen ? '#83B53C' : '#FFFFFF'}
            border="2px solid #83B53C"
            borderRadius="20px"
            marginBottom="80px"
            key={index}
          >
            <SimpleGrid
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap={['wrap', , 'nowrap', 'nowrap']}
              columns={[2, 2, 2, 2]}
              marginBottom="50px"
              position="relative"
              _after={{
                content: '""',
                position: 'absolute',
                right: 0,
                bottom: '-27px',
                height: '5px',
                width: '112px',
                background: section?.isGreen ? '#FFFFFF' : '#979797',
                // background: '#979797',
                borderRadius: '100px',
                display: ['none', 'block', '', ''],
              }}
            >
              <Box display="flex">
                <Text
                  as={'h3'}
                  fontSize={['28px', '', '', '36px']}
                  lineHeight={['36px', '', '', '44px']}
                  fontWeight="bold"
                  // color="#1E3240"
                  color={section.isGreen ? '#FFFFFF' : '#1E3240'}
                >
                  {section.title}
                </Text>
              </Box>
              {(section?.actions || []).map((item) => (
                <Box display="flex" as={Link} to={`/${item.to}`}>
                  <CommonButton
                    color={section.isGreen ? '#83B53C' : '#FFFFFF'}
                    iconColor="rgba(0, 0, 0, 0.837986)"
                    background={section.isGreen ? '#FFFFFF' : '#83B53C'}
                  >
                    {item.text}
                  </CommonButton>
                </Box>
              ))}
            </SimpleGrid>

            <RowWrapper>
              {(section?.subSections || []).map((card) => (
                <ColumnWrapper xs={12} sm={6} md={4} lg={4}>
                  <ExtraInfoCard card={card} isGreen={section.isGreen} />
                </ColumnWrapper>
              ))}
            </RowWrapper>
          </Box>
        </>
      ))}
    </>
  )
}

export default OurMansions
