import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background: #ffffff;
  ${({ enable }) =>
    enable &&
    css`
      display: none;
    `};

  @media {
    padding: 4em 0;
  }
`

export const IconGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  margin: 0 auto;
  text-align: center;
  align-items: center;
`

export const SubText = styled.p`
  text-align: center;
  margin: 0 auto;
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  max-width: 780px;
`

export const Icon = styled.img`
  display: flex;
  justify-content: center;
  padding: 0 0 48px 0;
  max-width: 48px;
  @media (min-width: 780px) {
    padding: 0 0 48px 0;
  }
`

export const DesktopGrid = styled.div``

export const Title = styled.h4`
  font-size: 24px;
  line-height: 40px;
  font-weight: 700;
  color: #1a4975;
  font-family: 'Poppins', sans-serif;
`

export const TextAlign = styled.div`
  display: flex;
  align-items: center;
`

export const AppDemoWrapper = styled.div`
  font-family: 'Poppins', sans-serif;
  position: relative;
  padding: 90px 150px;
  svg {
    position: absolute;
    z-index: -1;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    margin-top: 22px;
  }
  @media (max-width: 768px) {
    padding: 90px 15px;
  }
  @media (max-width: 599px) {
    padding: 45px 15px;
    background: #1a4975;
  }
`

export const AppTitle = styled.h1`
  line-height: 40px;
  color: #ffffff;

  padding: 0 72px;
  margin-top: 65px;
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  font-weight: 700;
  padding: 0 0 0 1.5em;
  max-width: 585px;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    line-height: 26px;
    font-size: 20px;
    margin-top: 23px;
  }

  @media (max-width: 599px) {
    padding: 0;
  }
`

export const AppSubTitle = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 1.47;
  color: #ffffff;

  font-family: 'Poppins', sans-serif;
  padding: 0 0 0 3em;
  max-width: 585px;
  margin-bottom: 2em;

  @media (max-width: 1199px) {
    padding: 0 0 0 2em;
  }

  @media (max-width: 599px) {
    padding: 0;
  }
`

export const Link = styled.a`
  display: flex;
  padding-bottom: 22px;
  max-width: 585px;
  padding-left: 3em;

  @media (max-width: 1199px) {
    padding: 0 0 0 2em;
  }

  @media (max-width: 599px) {
    padding: 0;
  }
`
export const AppDemoImg = styled.img`
  padding: 33px 44px 0;
  margin-left: 1em;
  position: relative;

  @media (max-width: 1199px) {
    margin-left: 0;
    padding: 0;
  }

  @media (max-width: 599px) {
    position: relative;
    top: 0;
  }
`

export const AppIMGWrapper = styled.img`
  display: flex;
  margin-right: 30px;
  @media (max-width: 599px) {
    max-width: 125px;
    margin-right: 15px;
  }
`
export const GridItems = styled.div`
  position: relative;
  flex-direction: row;
  display: flex;
  width: 315px;
  margin: 0 auto;
  text-align: center;
  > div {
    width: 100%;
  }

  @media (min-width: 991px) {
    :first-child + div:after,
    :first-child + div:before {
      content: '';
      height: 80px;
      width: 9px;
      background-color: transparent;
      border-left: 1px solid #f0e6ff;
      border-right: 1px solid #f0e6ff;
      top: 50%;
      margin-top: -40px;
      position: absolute;
    }
    :first-child + div:after {
      left: -50px;
    }
    :first-child + div:before {
      right: -50px;
    }
  }

  @media (max-width: 990px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    width: 30%;
  }

  @media (max-width: 599px) {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
`

export const TitleWrapper = styled.div`
  text-align: center;
  padding-bottom: 2em;
  h2 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1rem;
    line-height: 0;
  }
  @media (min-width: 780px) {
    display: flex;
    flex-direction: column;
    padding-bottom: 4em;
  }
`

export const H2 = styled.h2`
  font-size: 40px;
  line-height: 1.3;
  text-transform: uppercase;
  font-weight: 700;
  color: #1a4975;
  margin-bottom: 0;
  -webkit-letter-spacing: 2px;
  -moz-letter-spacing: 2px;
  -ms-letter-spacing: 2px;
  letter-spacing: 2px;
`

export const H4 = styled.h4`
  font-size: 22px !important;
  line-height: 1 !important;
  text-transform: uppercase;
  font-weight: 700;
  color: #d6df22;
  margin-bottom: 0;
  -webkit-letter-spacing: 2px;
  -moz-letter-spacing: 2px;
  -ms-letter-spacing: 2px;
  letter-spacing: 2px;
`

export const AppWrapper = styled.div`
  flex-direction: column;

  @media (min-width: 780px) {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
