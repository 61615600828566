import React from 'react'
import {
  Container,
  Box,
  Text,
  Image,
  SimpleGrid,
  Grid,
  GridItem,
  Flex,
  Button,
} from '@chakra-ui/react'
import {
  CircleIcon1,
  CircleIcon2,
  WhiteCircle,
  OrangeCircle,
  QuotesShap,
} from '../../../../assets/svgs'
import Quotes1 from '../../../../assets/pngs/quotes1.png'
import styled, { css } from 'styled-components'

const SubBannerWrapper = styled.section`
  display: inline-block;
  width: 100%;
  padding: 69px 0px 0px;
  position: relative;

  .circle_icon_1 {
    position: absolute;
    right: 0;
    top: 3%;
  }

  .circle_icon_2 {
    position: absolute;
    left: 0;
    bottom: -8%;
  }

  .shap_1 {
    position: absolute;
    left: 93px;
    top: 123px;
  }

  .shap_2 {
    position: absolute;
    right: 69px;
    bottom: 112px;
  }

  .shap_3 {
    position: absolute;
    top: -12px;
    right: 194px;
    z-index: -1;
  }

  @media (max-width: 767px) {
    .shap_1 {
      display: none;
    }
  }
`

const SubBannerImage = styled.div`
  background-image: url(${({ image }) => image && image});
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 644px;
  height: auto;
  width: 100%;
  overflow: hidden;
  border-radius: 50px 0px;
  /* position: relative; */
`
export const BannerBg = styled.div`
  display: inline-block;
  width: 100%;
  height: 644px;
  background: linear-gradient(
    198.73deg,
    rgba(0, 0, 0, 0.0001) 15.31%,
    rgba(0, 0, 0, 0.837986) 67.37%
  );
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`

const SubBannerTitle = styled.h1`
  span {
    color: #83b53c;
  }
`

const SubBanner = ({ title, description, actions = [], file }) => {
  return (
    <SubBannerWrapper>
      <CircleIcon1 className="circle_icon_1" />
      <CircleIcon2 className="circle_icon_2" />
      <WhiteCircle className="shap_1" boxSize={8} />
      <OrangeCircle className="shap_2" boxSize={8} />
      <OrangeCircle className="shap_3" boxSize={8} />

      <Container
        maxW={['container.sm', 'container.md', 'container.lg', 'container.xl']}
      >
        <Box
          position="relative"
          _before={{
            content: '""',
            position: 'absolute',
            top: '-39px',
            left: 0,
            height: '10px',
            width: '146px',
            background: '#83B53C',
          }}
          display={['none', 'none', 'block']}
        />
      </Container>

      <Box as={SubBannerImage} image={file.file.url}>
        <BannerBg>
          <Container
            maxW={[
              'container.sm',
              'container.md',
              'container.lg',
              'container.xl',
            ]}
          >
            <Box display="flex" flexFlow="column" alignItems="center">
              <Text
                as={SubBannerTitle}
                fontSize={['28px', '', '42px', '50px']}
                lineHeight={['36px', '', '50px', '58px']}
                // fontSize={{ base: 28, xs: 28, sm: 28, md: 28, lg: 50 }}
                // lineHeight={{ base: 36, xs: 36, sm: 26, md: 36, lg: 58 }}
                fontWeight="bold"
                color="#FFFFFF"
                letterSpacing="-2.01026px"
                textAlign="center"
                marginBottom="21px"
                dangerouslySetInnerHTML={{
                  __html: title || '',
                }}
              />

              <Text
                fontSize={['16px', '', '18px', '24px']}
                color="#FFFFFF"
                marginBottom="24px"
                textAlign="center"
              >
                {description.description}
              </Text>
              {(actions || []).map((item) => (
                <Box display="flex">
                  <Button
                    variant="solid"
                    fontSize={['14px', '16px', '18px', '24px']}
                    color="#FFFFFF"
                    background="#83B53C"
                    outline="none !important"
                    boxShadow="none"
                    fontWeight="bold"
                    borderRadius="18px 18px 0px 0px"
                    height={['60px', '', '', '80px']}
                    width={['auto', '', '518px', '518px']}
                    border="1px solid"
                    borderColor="#FFFFFF"
                    position="relative"
                    _after={{
                      content: '""',
                      position: 'absolute',
                      bottom: '-10px',
                      left: 0,
                      height: '5px',
                      width: '100%',
                      background: 'white',
                      borderRadius: '100px',
                    }}
                    _hover={{ background: '#83B53C' }}
                    _focus={{ boxShadow: 'none' }}
                  >
                    {item.text}
                  </Button>
                </Box>
              ))}
            </Box>
          </Container>
        </BannerBg>
      </Box>
    </SubBannerWrapper>
  )
}

export default SubBanner
